<template>
  <div v-if="appear_in === 'right_area'">
    <aside class="side_list">
      <h2><img :src="favorite_icon" alt=""><span class="span_txt">お気に入り求人</span></h2>

      <div class="f_list_box" v-if="fvp.length==0">
        <ul>
          <li v-if="fvp.length==0">
            <p class="f_list_cont noData">お気に入り求人に保存された情報はありません。
              <br>検索結果一覧や求人詳細の「お気に入りに保存」ボタンから保存することができます。
            </p>
          </li>
        </ul>
      </div>

      <div class="f_list_box" v-else>
        <ul>
          <li v-for="position in fvp" v-bind:key="position.id">
            <a v-bind:href="`${touhan_prefix}/search/result_detail/${position.position_cd}#search_result_efo_wrap`">
              <p class="title">{{ position.catchcopy }}</p>
              <p class="f_list_cont">{{ position.feature.slice(0, 60) }}</p>
            </a>
          </li>
        </ul>
        <div class="f_list_link">
          <a v-bind:href="`${touhan_prefix}/search/favorite`" class="button osusume_link">お気に入り求人一覧へ＞</a>
        </div>
      </div>
    </aside>

    <aside class="side_list topBox">
      <h2><img :src="history_icon" alt=""><span class="span_txt">閲覧履歴</span></h2>
      <div class="f_list_box">

        <ul v-if="bhp.length==0">
          <li><p class="noData">閲覧した求人はありません。</p></li>
        </ul>

        <ul v-else>
          <li v-for="position in bhp" v-bind:key="position.id">
            <a v-bind:href="`${touhan_prefix}/search/result_detail/${position.position_cd}#search_result_efo_wrap`">
              <p class="title">{{ position.catchcopy }}</p>
            </a>
          </li>
        </ul>

      </div>
    </aside>
  </div>
  <div v-else-if="appear_in === 'content_area' && (fvp.length > 0 || bhp.length > 0)">
    <section v-if="fvp.length > 0" class="search_recommend_vacancy Recommend_vacancy">
      <h3><img v-bind:src="recommend_vacancy_icon" alt="">お気に入り求人</h3>
      <div class="Recommend_box">
        <div class="txt_wrap">
          <ul class="cf">
            <li v-for="position in fvp" v-bind:key="position.id" style="width: 100%;">
                <a v-if="position.position_cd !== current_position_cd" v-bind:href="`${touhan_prefix}/search/result_detail/${position.position_cd}?suggest=favorite#search_result_efo_wrap`">
                  <p class="title">{{ position.catchcopy }}</p>
                </a>
            </li>
          </ul>
        </div>
        <div class="f_list_link">
          <a v-bind:href="`${touhan_prefix}/search/favorite`" class="button osusume_link">お気に入り求人一覧へ＞</a>
        </div>
      </div>
    </section>
    <section v-if="bhp.length > 0" class="search_recommend_vacancy Recommend_vacancy">
      <h3><img v-bind:src="recommend_vacancy_icon" alt="">求人の閲覧履歴</h3>
      <div class="Recommend_box">
        <div class="txt_wrap">
          <ul class="cf">
            <li v-for="position in bhp" v-bind:key="position.id" style="width: 100%;">
                <a v-if="position.position_cd !== current_position_cd" v-bind:href="`${touhan_prefix}/search/result_detail/${position.position_cd}?suggest=history#search_result_efo_wrap`">
                  <p class="title">{{ position.catchcopy }}</p>
                </a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  const appearance_area_list = {
    right_area: 'right_area',
    content_area: 'content_area'
  };

  export default {
    props: {
      fp_positions: { required: true, type: Array },
      browsing_history_positions: { required: true, type: Array },
      domain: { required: true, type: String },
      appear_in: { type: String, default: appearance_area_list.right_area, validator: value => {
        return Object.values(appearance_area_list).indexOf(value) !== -1;
      } },
      current_position_cd: { required: false, type: String },
    },
    data: function () {
      return {
        favorite_icon: "",
        touhan_prefix: "",
        history_icon: "",
        recommend_vacancy_icon: ''
      }
    },
    mounted: function() {
      if (this.domain === 'touhan' ) {
        this.favorite_icon = '/img/touhan/common/favorite.png';
        this.touhan_prefix = "/touhan";
        this.history_icon = "/img/touhan/common/history.png";
        this.recommend_vacancy_icon = '/img/touhan/search/recommend_vacancy.png';
      } else {
        this.favorite_icon = '/img/application/i_favorite.png';
        this.history_icon = "/img/application/i_history.png";
        this.recommend_vacancy_icon = '/img/search/i_Recommend_vacancy.png';
      }
    },
    computed: {
      // current_position_cd指定時(親画面で現在表示中の求人がある場合)は、お気に入り求人・閲覧履歴には表示させない
      fvp(){ return this.fp_positions.filter(position => position.position_cd !== this.current_position_cd) },
      bhp(){ return this.browsing_history_positions.filter(position => position.position_cd !== this.current_position_cd) }
    }
  }
</script>
