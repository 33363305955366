import Vue from 'vue/dist/vue.esm.js'
import $ from 'jquery'
import Cookies from 'js-cookie'
import FavoriteAndHistory from '@/javascripts/favorite_and_history.vue'
import agentAPI from '@/javascripts/utils/agent_api'
import { pharmacistFavoriteCount, touhanFavoriteCount } from '@/javascripts/utils/favorite_positions';

// 薬剤師登録販売者でkeyを分ける
const target_position_key = document.getElementById('target_position_key');
const domain = target_position_key.getAttribute('domain')
const cookie_num_max = target_position_key.getAttribute('cookie_num_max')
const fp_key = target_position_key.getAttribute('fp_key')
const hp_key = target_position_key.getAttribute('hp_key')

var favoriteAndHistoryVue = new Vue({
  data: function () {
    return {
      cookies_fps: [],
      cookies_bhs: [],
      all_positions: [],
      fp_positions: [],
      browsing_history_positions: [],
      domain: domain
    }
  },
  el: '#favoriteAndHistory',
  components: {
    'favorite_and_history': FavoriteAndHistory
  },
  mounted: function () {
    if(Cookies.get(fp_key)) { this.cookies_fps = Cookies.get(fp_key).split(',') }
    if(Cookies.get(hp_key)) { this.cookies_bhs = Cookies.get(hp_key).split(',') }
    this.setupPositions()
  },
  methods: {
    addFvp(add_position){ // お気に入りを追加
      this.all_positions.unshift(add_position)
      this.cookies_fps.unshift(add_position.id)
      this.updateFpPositions()
    },
    removeFvp(remove_position_id){ // お気に入りを削除
      let keyIndex = this.cookies_fps.indexOf(remove_position_id)
      if(keyIndex!=-1) { this.cookies_fps.splice(keyIndex,1) }
      this.updateFpPositions()
    },
    updateFpPositions(){
      this.fp_positions.splice(0)
      for (let i = 0; i < this.sliced_fps.length; i++) {
        let objectIndex = this.all_positions.findIndex(element => element.id === parseInt(this.sliced_fps[i]))
        if(objectIndex!=-1) { this.fp_positions.push(this.all_positions[objectIndex]) }
      }
    },
    updateBhPositions(){
      this.browsing_history_positions.splice(0)
      for (let j = 0; j < this.cookies_bhs.length; j++) {
        let objectIndex = this.all_positions.findIndex(element => element.id === parseInt(this.cookies_bhs[j]))
        if(objectIndex!=-1) { this.browsing_history_positions.push(this.all_positions[objectIndex]) }
      }
    },
    setupPositions(){
      let merged_keys = this.cookies_fps.concat(this.cookies_bhs)
      if(merged_keys.length == 0) { return true }
      agentAPI.get('/api/v1/position_by_ids?ids=' + merged_keys.join(',')).then((response) => {
        this.all_positions = response.data
        this.updateFpPositions()
        this.updateBhPositions()
      }, (error) => { console.log(error) });
    },
  },
  computed: {
    sliced_fps() { // お気に入り登録の表示は3件まで
      return this.cookies_fps.slice(0,3)
    }
  }
})

// 求人検索結果ページのお気に入り追加ボタン
$('.js-add-favorite').on('click', function(){
  let dataId = parseInt(this.getAttribute('data-id'))
  let dataCatchcopy = this.getAttribute('data-catchcopy')
  let dataFeature = this.getAttribute('data-feature')
  let dataPositionCd = this.getAttribute('data-cd')
  setCookieByDataCookieKey(fp_key, dataId)
  let vueRightAreaComponent = favoriteAndHistoryVue.$refs.right_area_component.$root
  vueRightAreaComponent.addFvp({ id: dataId, catchcopy: dataCatchcopy, feature: dataFeature, position_cd: dataPositionCd })
  if (domain === "touhan") {
    touhanFavoriteCount()
  } else {
    pharmacistFavoriteCount()
  }
})

// 求人詳細ページのお気入り追加ボタン
const onClickDetailCallback = function(){
  const dataId = this.getAttribute('data-id')
  setCookieByDataCookieKey(fp_key, dataId)
  if(domain === 'touhan') {
    touhanFavoriteCount()
  } else {
    pharmacistFavoriteCount()
  }
}
$('.touhan_js_add_favorite_btn_from_detail').on('click', onClickDetailCallback)
$('.js_add_favorite_btn_from_detail').on('click', onClickDetailCallback)

function setCookieByDataCookieKey(dataCookieKey, dataId){
  const expires_dt = new Date();
  expires_dt.setTime(expires_dt.getTime() +  (365 * 24 * 60 * 60 * 1000));
  if(Cookies.get(dataCookieKey)) {
    var th_favorite_ary = Cookies.get(dataCookieKey).split(',')
    if(th_favorite_ary.indexOf(dataId.toString()) > -1) { return true } // 重複時は何もしない
    th_favorite_ary.unshift(dataId.toString())
    Cookies.set(dataCookieKey, th_favorite_ary.slice(0,cookie_num_max).join(','), { "expires": expires_dt })
  } else {
    Cookies.set(dataCookieKey, dataId.toString(), { "expires": expires_dt })
  }
}

$('#favoritesList').on('click', '.js-remove-favorite', function(ev){
  let dataId = this.getAttribute('data-id')
  let fp_key = document.getElementById('target_position_key').getAttribute('fp_key')
  if(Cookies.get(fp_key)) { // Cookiesになければ何もしない
    let th_favorite_ary = Cookies.get(fp_key).split(',')
    let objectIndex = th_favorite_ary.indexOf(dataId)
    if(objectIndex == -1) { return true } // Cookiesに値がなければ何もしない
    th_favorite_ary.splice(objectIndex, 1)
    let expires_dt = new Date();
    expires_dt.setTime(expires_dt.getTime() +  (365 * 24 * 60 * 60 * 1000));
    Cookies.set(fp_key, th_favorite_ary.join(','), { "expires": expires_dt })
  }
  let vueRightAreaComponent = favoriteAndHistoryVue.$refs.right_area_component.$root
  vueRightAreaComponent.removeFvp(dataId)
  if (domain === "touhan") {
    touhanFavoriteCount()
  } else {
    pharmacistFavoriteCount()
  }
})
